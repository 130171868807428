/*
 * @Author: your name
 * @Date: 2021-03-09 14:23:12
 * @LastEditTime: 2024-10-10 14:32:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\assets\js\mixin\pageRefreshLoad.js
 */
export default {
    data() {
      return {
        dataSource: [],
        loading: false,
        refreshing: false,
        page: 0,
        pageSize:20,
        total: 0
      }
    },
    computed: {
      finished() {
        return this.dataSource.length >= this.total
      }
    },
    methods: {
      async onLoad() {
        this.page++
        this.loading = true
        try {
          if (this.refreshing) {
            this.dataSource = []
          }
          await this.getList()
        } catch (e) {
          console.log(e,'分页加载失败')
        } finally {
          // console.log('处理完成')
          this.loading = this.dataSource.length > 0 ? false : true
          this.refreshing = false
        }
      },
      async onRefresh() {
        this.page = 0
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.refreshing = true
        await this.onLoad()
      },
      getList() {}
    }
  }
  