<!--
* @Page: 价格公示
* @name: 张阳帅
* @Date: 2024-09-30 16:11:34
 * @LastEditTime: 2024-10-30 15:11:17
* @Description: 
 * @FilePath: \szlsnk-user-mp\src\views\gjc\hosp\priceList.vue
-->
<template>
  <div class="price_page">
    <van-sticky offset-top="50px">
      <div class="page_top">
        <van-tabs v-model="activeName" type="card" color="#3A8AE5" background="#fff" @click="onTableChange">
          <van-tab title="药品价格标准" name="1" />
          <van-tab title="医疗服务价格标准" name="2" />
        </van-tabs>
      </div>
    </van-sticky>
    <div class="page_body">
      <page-refresh-load :data-source="dataSource" :total="total" :refreshing="refreshing" :loading="loading" @refreshing="onRefresh" @load-more="onLoad">
        <gjc-price-cell :head-obj="headData" :data="dataSource" :columns-data="columnsData" />
      </page-refresh-load>
    </div>
  </div>
</template>
<script>
    import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'
    import { gjcModel } from '@/api/gjcApi'

    export default {
        name:'PriceList',
        mixins: [pageRefreshLoad],
        data(){
            return{
                dataSource:[],
                pageSize:20,
                activeName:'1',
                headData:[
                  '药品名称',
                  '药品规格',
                  '药品价格（元）'
                ],
                columnsData:[
                  'drugName',
                  'spec',
                  'unitPrice'
                ]
            }
        },
        mounted(){
          this.onRefresh()
        },
        methods:{
          async getList(){
            let params={
                yydm: '',//医院代码（医院唯一标识）
                page: this.page,
                pageSize: this.pageSize
            }
            let methods = this.activeName ==='1' ? 'getDrugPageListData' : 'getInspectItemInfoPageListData'
            const res = await gjcModel[methods](params)
            this.dataSource.push(...(res.data || []))
            this.total= res.count || 0
          },
          onTableChange(val) {
            console.log(val,'table change')
            if (val==='1') {
               this.headData=[
                  '药品名称',
                  '药品规格',
                  '药品价格（元）'
                ],
                this.columnsData=[
                  'drugName',
                  'spec',
                  'unitPrice'
                ]
            }else{
              this.headData=[
                  '项目名称',
                  '价格（元）'
                ],
                this.columnsData=[
                  'itemName',
                  'itemPrice'
                ]
            }
            this.onRefresh()
          }
        }
    }
</script>
<style scoped  lang='less'>
    .price_page{
        background-color: white;
        .page_top{
            padding: 10px 0;
            background-color: white;
        }
    }
    /deep/.van-list{
        padding: 0 !important;
    }
    .tipTxt{
        padding: 24px 32px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        background: #DC3030;
        margin-bottom: 24px;
    }
</style>
    